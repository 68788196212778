<template>
  <div>
    <v-card
      :loading="loading"
      style="
        background-color: rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(12px);
        -webkit-backdrop-filter: blur(12px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      "
    >
      <v-toolbar flat dense>
        <v-toolbar-title style="font-size: 16px"
          >Products & Services</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn
          icon
          color="primary"
          v-if="enableSave"
          @click="saveProductService()"
          :loading="savingProduct"
        >
          <v-icon small>save</v-icon>
        </v-btn>
        <v-btn @click="closeModal()" icon color="redPop">
          <v-icon small>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="my-0">
        <v-row class="pa-1">
          <v-col cols="3">
            <v-subheader style="font-size: 14px">Categories</v-subheader>
            <v-divider></v-divider>
            <v-treeview
              :items="categories"
              item-key="id"
              return-object
              hoverable
              dense
              shaped
              activatable
              :active.sync="selectedCategory"
            >
              <template v-slot:append="{ item }">
                <v-chip small color="greyRaised" style="font-size: 11px">
                  {{ item.count }}
                </v-chip>
              </template>
            </v-treeview>
            <!-- <v-treeview v-model="selectedCategory" return-object item-key="id" :items="categories" hoverable dense shaped activatable></v-treeview> -->
          </v-col>
          <v-col cols="6">
            <v-text-field
              class="mt-2"
              hide-details
              placeholder="Search"
              v-model="search"
              dense
              outlined
              clearable
            ></v-text-field>
            <v-list dense class="my-1 py-0">
              <v-list-item v-for="product in filterProducts" :key="product.id">
                <v-list-item-action class="mr-1">
                  <v-avatar
                    class="mr-2"
                    size="30"
                    :color="!product.icon ? 'secondary' : ''"
                  >
                    <v-img
                      v-if="product.icon"
                      :src="product.icon"
                      contain
                    ></v-img>
                    <h3 v-else>{{ product.name.charAt(0) }}</h3>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 12px"
                    class="text-wrap"
                    >{{ product.name }}</v-list-item-title
                  >
                  <v-list-item-subtitle
                    style="font-size: 11px"
                    v-if="product.type == 'PRODUCT'"
                  >
                    <v-icon color="grey" class="mr-1 mb-1" small
                      >category</v-icon
                    >
                    Product</v-list-item-subtitle
                  >
                  <v-list-item-subtitle style="font-size: 11px" v-else>
                    <v-icon color="grey" class="mr-1 mb-1" small
                      >engineering</v-icon
                    >
                    Service</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    style="font-size: 11px"
                    class="text-wrap"
                  >
                    {{ product.description }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip top v-if="!selectedProducts[product.id]">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        v-on="on"
                        icon
                        @click="selectProduct(product)"
                      >
                        <v-icon small>add_shopping_cart</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">Add to cart</span>
                  </v-tooltip>
                  <v-chip small v-else :key="key" outlined style="border: none">
                    <v-btn
                      class="ml-0 pl-0"
                      icon
                      small
                      left
                      :disabled="selectedProducts[product.id].quantity == 0"
                      color="redPop"
                      @click="changeQuantity(product.id, -1)"
                    >
                      <v-icon small>remove</v-icon>
                    </v-btn>
                    <span class="mx-2">{{
                      selectedProducts[product.id].quantity
                    }}</span>
                    <v-btn
                      class="mr-0 pr-0"
                      icon
                      small
                      right
                      color="success"
                      @click="changeQuantity(product.id, 1)"
                    >
                      <v-icon small>add</v-icon>
                    </v-btn>
                  </v-chip>
                  <!-- <el-input-number v-else class="my-0 py-0"  :step="1" size="mini" @change="logData()" v-model="selectedProducts[product.id].quantity"></el-input-number> -->
                  <!-- <v-icon v-if="product.selected" color="success"
                    >check_circle</v-icon
                  > -->
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="filterProducts.length == 0">
                <v-list-item-content class="text-center">
                  <span style="color: grey; font-size: 12px"
                    >No products found</span
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="3">
            <v-list dense subheader :key="key">
              <v-subheader style="font-size: 14px"
                >Selected Products</v-subheader
              >
              <v-divider></v-divider>
              <v-list-item
                v-for="selected in Object.values(selectedProducts)"
                :key="selected.productId"
              >
                <v-list-item-action class="mr-2">
                  <v-chip small>{{ selected.quantity }}</v-chip>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    {{ selected.productService.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="text-wrap"
                    style="font-size: 11px"
                    v-if="
                      selected.productServiceCategory &&
                      selected.productServiceSubcategory
                    "
                  >
                    {{ selected.productServiceCategory.name }}
                    <v-icon class="mx-1" x-small>chevron_right</v-icon>
                    {{ selected.productServiceSubcategory.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    icon
                    @click="selectProduct(selected.productService)"
                    color="red"
                  >
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="Object.keys(selectedProducts).length == 0">
                <v-list-item-content class="text-center">
                  <span style="font-size: 12px; color: grey"
                    >No products selected</span
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

    <script>
export default {
  props: {
    level: {
      type: String,
      default: "SHIPMENT",
    },
    enableSave: {
      type: Boolean,
      default: false,
    },
    openKey: {
      type: Number,
    },
    preselected: {
      type: Array,
      default: () => [],
    },
    properties: {
      type: Object,

      default: () => ({
        bookingId: null,
        bookingContainerId: null,
      }),
    },
  },
  data: () => ({
    allCategories: [],
    categories: [],
    filteredProducts: [],
    loading: false,
    key: 100,
    products: [],
    removedProducts: [],
    savingProduct: false,
    search: null,
    selectedCategory: [],
    selectedProducts: {},
  }),
  watch: {
    level: {
      immediate: true,
      handler(val) {
        this.applyFilter();
      },
    },
    openKey: {
      immediate: true,
      handler(val) {
        console.log("Open key", val, this.preselected)
        if (val) {

          this.selectedProducts = {};
          for (let item of this.preselected) {
            this.selectedProducts[item.productServiceId] = {
              id: item.id,
              quantity: item.quantity,
              productServiceId: item.productService.id,
              productService: item.productService,
              productServiceCategory:
                item.productService.productServiceCategory,
              productServiceSubcategory:
                item.productService.productServiceSubcategory,
              key: 100,
            };
          }
        }
      },
    },
    selectedCategory: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.applyFilter(val[0].type, val[0].id);
        } else {
          this.applyFilter();
        }
      },
    },
  },
  computed: {
    filterProducts() {
      let result = this.filteredProducts;
      if (this.search) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return result;
    },
    formatSelection() {
      let result = [];
      for (let key in this.selectedProducts) {
        if (this.selectedProducts[key].quantity > 0) {
          result.push({ ...this.selectedProducts[key], ...this.properties });
        }
      }
      return result;
    },
    // removedProducts() {
    //   let result = [];
    //   for (let key in this.selectedProducts) {
    //     if (this.selectedProducts[key].id && this.selectedProducts[key].quantity == 0) {
    //       result.push(this.selectedProducts[key].id);
    //     }
    //   }
    //   return result;
    // },
  },
  created() {
    this.getProducts();
  },
  methods: {
    applyFilter(type = null, id = null) {
      let levelFilter = this.products.filter((x) => x.level == this.level);
      this.categories = this.allCategories.filter((x) =>
        levelFilter.map((y) => y.productServiceCategoryId).includes(x.id)
      );
      for (let product of this.categories) {
        product.count = levelFilter.filter(
          (x) => x.productServiceCategoryId == product.id
        ).length;
        for (let sub of product.children) {
          sub.count = levelFilter.filter(
            (x) => x.productServiceSubcategoryId == sub.id
          ).length;
        }
      }
      if (type == "CATEGORY") {
        this.filteredProducts = levelFilter.filter(
          (x) => x.productServiceCategoryId == id
        );
      } else if (type == "SUBCATEGORY") {
        this.filteredProducts = levelFilter.filter(
          (x) => x.productServiceSubcategoryId == id
        );
      } else {
        this.filteredProducts = levelFilter;
      }
      this.filterProducts.forEach(x=>{
        if(this.selectedProducts[x.id]){
          x.selected = true
        }
      })
    },
    changeQuantity(id, quantity) {
      this.selectedProducts[id].quantity += quantity;
      if (
        this.selectedProducts[id].id &&
        this.selectedProducts[id].quantity == 0
      ) {
        this.removedProducts.push(this.selectedProducts[id].id);
      } else if (
        this.selectedProducts[id].id &&
        this.selectedProducts[id].quantity > 0
      ) {
        this.removedProducts = this.removedProducts.filter(
          (x) => x != this.selectedProducts[id].id
        );
      }
      this.$emit("selected", this.formatSelection);
      this.key++;
    },
    closeModal() {
      this.$emit("close");
      this.selectedProducts = {};
    },

    async getProducts() {
      this.loading = true;
      this.products = await this.$API.getProductServices();
      let uniqueCategories = [
        ...new Set(this.products.map((x) => x.productServiceCategoryId)),
      ];
      let categories = [];
      for (let i = 0; i < uniqueCategories.length; i++) {
        let category = this.products.find(
          (x) => x.productServiceCategoryId == uniqueCategories[i]
        );
        category = category ? category.productServiceCategory : null;
        if (category) {
          let children = [
            ...new Set(
              this.products
                .filter(
                  (x) => x.productServiceCategoryId == uniqueCategories[i]
                )
                .map((x) => x.productServiceSubcategoryId)
            ),
          ];
          let obj = {
            ...category,
            children: [],
            count: this.products.filter(
              (x) => x.productServiceCategoryId == uniqueCategories[i]
            ).length,
            type: "CATEGORY",
          };
          for (let child of children) {
            let find = this.products.find(
              (x) => x.productServiceSubcategoryId == child
            );
            if (find.productServiceSubcategory) {
              let count = this.products.filter(
                (x) => x.productServiceSubcategoryId == child
              ).length;
              find.productServiceSubcategory.count = count;
              find.productServiceSubcategory.type = "SUBCATEGORY";
              obj.children.push(find.productServiceSubcategory);
            }
          }
          categories.push(obj);
        }
      }
      this.allCategories = categories;
      this.applyFilter();
      this.loading = false;
    },
    async saveProductService() {
      this.savingProduct = true;
      let result = await this.$API.bulkUpdateProductServiceSales({
        batch: this.formatSelection,
        removed: this.removedProducts,
      });
      this.$emit("selected", result);
      this.$message.success("Saved successfully!");
      this.$emit("close");
      this.savingProduct = false;
    },
    selectProduct(product) {
      let find = this.selectedProducts[product.id];
      if (find) {
        if (this.selectedProducts[product.id].id) {
          this.removedProducts.push(this.selectedProducts[product.id].id);
        }
        delete this.selectedProducts[product.id];
        product.selected = false;
      } else {
        this.selectedProducts[product.id] = {
          ...this.properties,
          productServiceId: product.id,
          productService: product,
          productServiceCategory: product.productServiceCategory,
          productServiceSubcategory: product.productServiceSubcategory,
          quantity: 1,
          key: 100,
        };
        product.selected = true;
      }
      this.$emit("selected", this.formatSelection);
      this.key++;
    },
  },
};
</script>